@import url(bootstrap/dist/css/bootstrap.min.css);
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fraunces:wght@400;700&display=swap");
header {
  height: 100%;
  z-index: -10;
  position: relative; }
  header svg {
    z-index: -1; }

.paralax-animation {
  display: none; }

.tablet-animation {
  display: none; }

.mobile-animation {
  display: block; }

@media (min-width: 600px) {
  .paralax-animation {
    display: none; }
  .tablet-animation {
    display: block; }
  .mobile-animation {
    display: none; } }

@media (min-width: 1024px) {
  .paralax-animation {
    display: block; }
  .tablet-animation {
    display: none; }
  .mobile-animation {
    display: none; } }

nav {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1998;
  background: #001819;
  color: #FFFCFB;
  font-family: Montserrat, sans-serif !important;
  font-size: 20px;
  font-weight: 700;
  transition: all 0.3s;
  text-align: left;
  line-height: 20px; }
  nav * div {
    cursor: pointer; }
  nav .close-nav {
    font-size: 30px;
    font-weight: normal;
    position: relative;
    right: 20%;
    cursor: pointer; }

.sidebar.active {
  left: 0; }

.content {
  width: 10%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column; }
  .content:hover {
    text-decoration: none; }

.nav__content-hamburger-signature {
  z-index: 999;
  display: block;
  line-height: 0;
  font-family: Montserrat, sans-serif !important;
  font-size: 15px;
  color: #0D3236 !important; }

a.btn-customized {
  margin-left: 5%;
  color: #001819;
  box-shadow: none; }
  a.btn-customized:hover {
    text-decoration: none; }

.dismiss {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  transition: all 0.3s;
  text-align: center;
  cursor: pointer; }

.sidebar .hide-sidebar-btn {
  padding: 40px 20px; }

.sidebar ul li a,
.sidebar ul li span {
  display: block;
  padding: 10px 20px;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin-left: 10%; }

.sidebar ul li a:hover {
  text-decoration: none; }

.nav-collapse__first-level {
  display: block;
  list-style-type: none;
  margin-left: 5%;
  text-indent: 5%;
  line-height: 30px; }
  .nav-collapse__first-level li {
    font-weight: 500; }

.nav-collapse__second-level {
  display: block;
  list-style-type: none; }
  .nav-collapse__second-level li {
    font-weight: 500;
    line-height: 30px; }

.collapse-inactive {
  display: none; }

.rotate-180-start {
  -webkit-animation: rotate-180-start 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-180-start 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes rotate-180-start {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

@keyframes rotate-180-start {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

.rotate-180-back {
  -webkit-animation: rotate-180-back 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: rotate-180-back 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@-webkit-keyframes rotate-180-back {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

@keyframes rotate-180-back {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(0);
    transform: rotate(0); } }

a.nav-footer-branding {
  display: block;
  width: 150px;
  height: 100px;
  background: url("../assets/images/nav-footer-branding.png");
  background-size: contain;
  margin-bottom: 40px; }

.open-menu {
  position: fixed;
  top: 3%;
  left: -2%;
  z-index: 999; }

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1; }
  .backdrop.active {
    z-index: 102;
    opacity: 0.2; }

.menu .menu-wrapper {
  z-index: 101;
  position: fixed;
  top: 5vh;
  left: 30px;
  display: flex; }

@media (min-width: 1920px) {
  .menu .menu-wrapper {
    transform: translateX(calc((100vw - 1920px) / 2)); } }

.menu .open-nav {
  width: 50px;
  z-index: 1000;
  cursor: pointer; }
  .menu .open-nav .open-nav-icon {
    color: #0D3236; }

.menu .menu-bar {
  opacity: 0;
  transform: translateX(-250px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; }
  .menu .menu-bar.open {
    opacity: 1;
    transform: translateX(0px); }

@media (max-width: 1024px) {
  .menu .open-nav {
    left: 15px; } }

.menu .grassgo-logo {
  margin-left: 60px;
  width: 280px;
  opacity: 1;
  transition-property: opacity;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out; }
  .menu .grassgo-logo.past {
    opacity: 0; }

@media (max-width: 1024px) {
  .menu .grassgo-logo {
    left: 110px;
    width: 210px; } }

.about-us {
  background-color: white; }
  .about-us a {
    text-decoration: none; }
  .about-us a:visited {
    color: white;
    text-decoration: none; }
  .about-us h1 {
    font-size: 25px;
    line-height: 40px;
    font-family: Fraunces, sans-serif;
    font-weight: bold;
    color: #006466; }
  .about-us p {
    font-size: 15px;
    line-height: 30px;
    color: #0D3236;
    font-family: Montserrat, sans-serif; }
  .about-us .slideInRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible; }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.book-now-up {
  position: relative; }

.packages__bar {
  width: 100%;
  height: 200px;
  background-color: #006466;
  color: #FAEBDC; }
  .packages__bar .arrow-down {
    width: 50px;
    height: 70px; }

.sea-list tr td,
.sky-list tr td {
  font-family: Montserrat, sans-serif !important;
  background: url("../assets/images/kropka-zielona.svg") no-repeat top left;
  background-size: 20px;
  background-position: 0 8px;
  line-height: 1.5625;
  padding: 0 4px calc(1.5rem + 4px) 30px;
  margin-left: 5px;
  font-size: 15px;
  line-height: 30px;
  vertical-align: top;
  color: #0D3236; }
  .sea-list tr td:first-child,
  .sky-list tr td:first-child {
    padding-right: 3rem; }

.sea-standard-package {
  background: #FAEBDC;
  color: #006466; }
  .sea-standard-package h1 {
    font-size: 35px;
    line-height: 40px; }
  .sea-standard-package h3 {
    font-size: 20px;
    font-family: Montserrat, sans-serif !important;
    line-height: 24px; }
  .sea-standard-package p {
    font-size: 15px;
    line-height: 30px;
    color: #0D3236; }
  .sea-standard-package ul li {
    color: #0D3236; }
  .sea-standard-package .see-more-btn,
  .sea-standard-package .book-now-btn {
    width: 218px;
    height: 45px;
    border-radius: 23px;
    background-color: #006466;
    font-weight: 700;
    border: none; }
    .sea-standard-package .see-more-btn a,
    .sea-standard-package .book-now-btn a {
      color: white;
      border: none;
      text-decoration: none; }
  .sea-standard-package .booking-info p {
    font-size: 10px;
    line-height: 25px; }
  .sea-standard-package .book-now-btn {
    background-color: #FF8864;
    justify-self: flex-end; }

.sky-premium-package {
  background: #FFFCFB;
  color: #006466; }
  .sky-premium-package h1 {
    font-size: 35px;
    line-height: 40px; }
  .sky-premium-package h3 {
    font-size: 20px;
    line-height: 24px;
    font-family: Montserrat, sans-serif !important; }
  .sky-premium-package p {
    font-size: 15px;
    line-height: 30px;
    color: #0D3236; }
  .sky-premium-package ul li {
    color: #0D3236; }
  .sky-premium-package .booking-info p {
    font-size: 10px;
    line-height: 25px; }
  .sky-premium-package .book-now-btn {
    background-color: #FF8864;
    justify-self: flex-end; }

.earth-eco-package {
  background: #006466;
  color: #FFFCFB; }
  .earth-eco-package h1 {
    font-size: 35px;
    line-height: 40px; }
  .earth-eco-package h3 {
    font-size: 20px;
    font-family: Montserrat, sans-serif !important;
    line-height: 24px; }
  .earth-eco-package p {
    font-size: 15px;
    line-height: 30px; }
  .earth-eco-package .cards-container .small-card {
    background: #FAEBDC;
    color: #0D3236;
    border-radius: 10px;
    height: 400px; }
    .earth-eco-package .cards-container .small-card .small-card-title {
      color: #006466; }
      .earth-eco-package .cards-container .small-card .small-card-title h1 {
        font-family: Fraunces, sans-serif;
        font-size: 70px;
        line-height: 94px;
        font-weight: 800; }
      .earth-eco-package .cards-container .small-card .small-card-title span {
        font-family: Fraunces, sans-serif;
        font-size: 30px;
        line-height: 40px;
        font-weight: bold; }
    .earth-eco-package .cards-container .small-card .small-card-text {
      font-family: Montserrat, sans-serif;
      color: #0D3236;
      font-size: 13px;
      line-height: 25px; }
    .earth-eco-package .cards-container .small-card .see-more-btn {
      background: #006466;
      color: white;
      font-size: 15px;
      line-height: 35px;
      font-weight: bold; }
  .earth-eco-package .cards-container .big-card {
    background: #FAEBDC;
    color: #0D3236;
    border-radius: 10px;
    padding: 30px; }
    .earth-eco-package .cards-container .big-card .big-card-title {
      color: #006466; }
      .earth-eco-package .cards-container .big-card .big-card-title h1 {
        font-size: 70px;
        line-height: 94px;
        font-weight: bold; }
      .earth-eco-package .cards-container .big-card .big-card-title span {
        font-family: Fraunces, sans-serif;
        font-size: 30px;
        line-height: 40px;
        font-weight: bold; }
    .earth-eco-package .cards-container .big-card .big-card-body h2 {
      color: #006466;
      font-family: Fraunces, sans-serif;
      font-weight: bold;
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 0px; }
    .earth-eco-package .cards-container .big-card .big-card-body p {
      color: #0D3236;
      font-family: Montserrat, sans-serif;
      font-size: 13px;
      line-height: 25px; }
    .earth-eco-package .cards-container .big-card .big-card-buttons .take-me-back-btn {
      background-color: #006466 !important;
      color: #FFFCFB;
      width: 218px;
      height: 45px; }
    .earth-eco-package .cards-container .big-card .big-card-buttons .all-packages-btn {
      width: 218px;
      height: 45px;
      border-radius: 23px;
      background-color: #006466;
      color: white;
      font-weight: 700;
      border: none; }
    .earth-eco-package .cards-container .big-card .big-card-buttons .book-now-btn {
      background-color: #FF8864;
      color: white;
      width: 218px;
      height: 45px; }
  .earth-eco-package .see-more-btn,
  .earth-eco-package .book-now-btn,
  .earth-eco-package .earth__see-more-btn {
    width: 218px;
    height: 45px;
    align-self: flex-end;
    border-radius: 23px;
    background-color: #FAEBDC;
    color: #006466;
    font-weight: 700;
    border: none; }
    .earth-eco-package .see-more-btn a,
    .earth-eco-package .book-now-btn a,
    .earth-eco-package .earth__see-more-btn a {
      color: white;
      border: none;
      text-decoration: none; }
  .earth-eco-package .earth__see-more-btn {
    background: #FAEBDC;
    color: #006466; }
  .earth-eco-package .book-now-btn {
    background-color: #FF8864; }

.one-off-package {
  background: #fff; }
  .one-off-package h1 {
    font-family: Fraunces, sans-serif;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    color: #006466; }
  .one-off-package p {
    font-size: 15px;
    line-height: 30px; }
  .one-off-package .book-now-btn {
    font-weight: bold;
    width: 218px;
    height: 45px;
    background-color: #FF8864;
    border: none;
    border-radius: 23px; }

#root
> div
> section.d-flex.flex-column.justify-content-center.one-off-package.py-2
> div
> div.col-12.order-4
> div
> div
> div.slick-list
> div
> div
> div {
  margin-right: 10px;
  margin-left: 10px; }

.my-carousel-item {
  margin: 5% auto;
  padding: 20px 0;
  background-color: #fffcfb;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(116, 104, 104, 0.75);
  -moz-box-shadow: 0px 3px 6px 0px rgba(116, 104, 104, 0.75);
  box-shadow: 0px 3px 6px 0px rgba(116, 104, 104, 0.75); }
  .my-carousel-item p {
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    color: #006466;
    margin-bottom: 0; }

.commercial-and-public-section {
  background: #FAEBDC;
  color: #006466; }
  .commercial-and-public-section h1 {
    font-weight: 700;
    font-size: 35px;
    line-height: 40px; }
  .commercial-and-public-section p {
    font-size: 15px;
    line-height: 30px;
    color: #0D3236; }
  .commercial-and-public-section .see-more-btn,
  .commercial-and-public-section .book-now-btn {
    width: 218px;
    height: 45px;
    border-radius: 23px;
    background-color: #006466;
    font-weight: 700;
    border: none; }
    .commercial-and-public-section .see-more-btn a,
    .commercial-and-public-section .book-now-btn a {
      color: white;
      border: none;
      text-decoration: none; }
  .commercial-and-public-section .sea-content__hidden--list {
    padding-left: 4% !important; }
  .commercial-and-public-section .booking-info p {
    font-size: 10px;
    line-height: 25px; }
  .commercial-and-public-section .book-now-btn {
    background-color: #FF8864;
    justify-self: flex-end; }

.form-section {
  padding-left: 10%;
  padding-right: 10%;
  background-color: #fff; }
  .form-section input,
  .form-section textarea {
    background-color: #FAEBDC !important; }
  .form-section .personal-data-input {
    width: 100%;
    color: #757575 !important;
    margin-bottom: 30px !important; }
    .form-section .personal-data-input input {
      width: 100%; }
  .form-section .form__title {
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    color: #006466; }
  .form-section .form__phone-number {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    line-height: 35px;
    color: #0D3236; }
  .form-section .styled-checkbox {
    position: absolute;
    opacity: 0; }
    .form-section .styled-checkbox + label {
      position: relative;
      cursor: pointer;
      padding: 0; }
    .form-section .styled-checkbox + label:before {
      content: '';
      margin-right: 15px;
      display: inline-block;
      vertical-align: text-top;
      width: 23px;
      height: 23px;
      background: #D1D3D1; }
    .form-section .styled-checkbox:checked + label:before {
      background: #006466; }
    .form-section .styled-checkbox:checked + label:after {
      content: '';
      position: relative;
      background-image: url("../assets/images/check.svg");
      background-position: center center;
      background-size: 12px 12px;
      background-repeat: no-repeat;
      left: calc(-100% + 23px);
      vertical-align: text-top;
      color: white;
      display: inline-block;
      width: 23px;
      height: 23px; }
  .form-section input[type='radio'] + label {
    cursor: pointer; }
  .form-section input[type='radio'] {
    position: relative;
    top: 5px; }
  .form-section input[type='radio'] + label::before {
    cursor: pointer; }
  .form-section input[type='radio']:after {
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 15px;
    top: -6px;
    left: -2px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 4px solid white; }
  .form-section input[type='radio']:checked:after {
    background-color: #006466; }
  .form-section .japanese {
    width: 150% !important; }
  .form-section .form__mail {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    line-height: 35px;
    color: #0D3236; }
  .form-section .p-success {
    font-size: 30px;
    line-height: 45px;
    color: #006466; }
  .form-section input[type="date"]:invalid::-webkit-datetime-edit {
    color: #757575 !important; }
  .form-section .input-address,
  .form-section .input-postcode {
    padding-left: 2% !important; }
  .form-section .p-tellUs {
    font-size: 20px;
    line-height: 25px;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    color: #006466; }
  .form-section .form-label {
    font-size: 12px;
    line-height: 30px;
    font-family: Montserrat, sans-serif;
    color: #0D3236; }
  .form-section .next-btn {
    width: 218px;
    height: 45px;
    border-radius: 23px;
    color: #FFFCFB;
    font-family: Montserrat, sans-serif;
    background-color: #006466;
    font-weight: 700;
    border: none; }
    .form-section .next-btn a {
      color: #FFFCFB;
      border: none;
      text-decoration: none; }
  .form-section .form__container .summary-p {
    font-family: Fraunces, sans-serif !important;
    font-weight: 700;
    color: #006466;
    font-size: 20px;
    line-height: 25px;
    text-align: center !important; }
  .form-section .form__container .message-p {
    font-size: 18px !important;
    line-height: 25px; }
  .form-section .form__container .answer-p {
    font-family: Fraunces, sans-serif !important;
    color: #006466;
    font-size: 20px;
    line-height: 25px; }
  .form-section .form__container label {
    color: #0D3236;
    font-family: Montserrat, sans-serif !important;
    font-size: 15px;
    line-height: 30px; }
  .form-section .back-btn {
    font-family: Montserrat, sans-serif !important;
    font-weight: 700;
    font-size: 20px !important;
    line-height: 35px;
    cursor: pointer; }
  .form-section .input-name,
  .form-section .input-email,
  .form-section .input-tel,
  .form-section .input-date,
  .form-section .input-postcode,
  .form-section .input-address,
  .form-section .input-notes,
  .form-section .input-other {
    background: #f9f2ef;
    border: 0;
    padding: 1%;
    font-family: Montserrat, sans-serif !important;
    font-size: 20px; }
    .form-section .input-name:focus,
    .form-section .input-email:focus,
    .form-section .input-tel:focus,
    .form-section .input-date:focus,
    .form-section .input-postcode:focus,
    .form-section .input-address:focus,
    .form-section .input-notes:focus,
    .form-section .input-other:focus {
      outline: none !important; }
    .form-section .input-name.error,
    .form-section .input-email.error,
    .form-section .input-tel.error,
    .form-section .input-date.error,
    .form-section .input-postcode.error,
    .form-section .input-address.error,
    .form-section .input-notes.error,
    .form-section .input-other.error {
      border: solid 1px #d1573b; }
  .form-section div.error {
    margin-top: 6px;
    font-size: 12px;
    line-height: 12px;
    color: #d1573b; }
  .form-section input.input-postcode {
    margin-left: 12px;
    width: calc(100% - 12px); }
  .form-section input.input-postcode + .error {
    margin-left: 12px; }
  .form-section .form-popup__bg {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 25, 26, 0.79);
    z-index: 50;
    /* ----------------------------------------------
 * Generated by Animista on 2021-4-7 13:23:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
    /**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */ }
    .form-section .form-popup__bg .form-popup {
      position: relative;
      width: 100vw;
      height: 100vh;
      background: #006466;
      color: #FFFCFB;
      z-index: 51; }
      .form-section .form-popup__bg .form-popup .flower-img {
        width: 70px; }
      .form-section .form-popup__bg .form-popup p {
        font-family: Fraunces, sans-serif !important;
        font-weight: bold;
        margin-top: 20px; }
      .form-section .form-popup__bg .form-popup .rotate-center {
        -webkit-animation: rotate-center 2s linear infinite both;
        animation: rotate-center 2s linear infinite both; }

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

footer {
  font-family: Montserrat, sans-serif !important;
  font-size: 13px !important;
  line-height: 20px;
  color: #0D3236;
  background-color: white; }
  footer a {
    color: #0D3236; }
    footer a:hover {
      color: #0D3236;
      text-decoration: none; }
  footer .copyright {
    line-height: 35px; }

.barrow-animation-wrapper {
  position: relative;
  top: -48px; }

.splash-container {
  height: 100vh;
  position: relative;
  transition-property: transform;
  transition-duration: 0.2s; }
  .splash-container .inner-wrapper {
    height: 100hv;
    width: 100%; }
  .splash-container.off .inner-wrapper {
    margin-top: 150px;
    position: static; }
  .splash-container .layer-wrapper {
    width: 100%;
    transition-property: transform;
    transition-duration: 0.2s;
    min-height: 100vh; }
  .splash-container .layer-wrapper > div {
    min-height: 100vh !important;
    max-width: 100%; }
  .splash-container .layer-wrapper > div > svg {
    min-height: 100vh !important; }
  .splash-container .layer-wrapper:not(.layer-d) {
    position: absolute; }
  .splash-container .layer-a {
    position: relative;
    z-index: 99; }
  .splash-container .layer-b {
    z-index: 98; }
  .splash-container .layer-c {
    z-index: 97;
    margin-left: 50%; }
  .splash-container .layer-d {
    z-index: 96; }
  .splash-container .image-c {
    z-index: -1;
    position: absolute;
    top: 0;
    margin-left: 50%;
    transition-property: transform;
    transition-duration: 0.2s; }

.layer-wrapper div {
  height: 100vh;
  width: 24%; }

.overflow-margin {
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 100; }
  .overflow-margin.left {
    transform: translateX(calc(-100% + ((100vw - 1920px)/2) + 2px)); }
  .overflow-margin.right {
    left: calc(1920px + ((100vw - 1920px - 64px)) / 2); }

@media only screen and (max-width: 1920px) {
  .overflow-margin {
    display: none; } }

@media (min-width: 767px) {
  .form-section input[type='radio'] {
    position: relative;
    top: 0px; }
  .form__title {
    font-size: 50px !important;
    line-height: 35px !important; }
  .personal-data-input {
    width: 75% !important; }
  .japanese-div p {
    line-height: 15px !important;
    margin-top: 5px; }
  .japanese-div .japanese-title {
    line-height: 25px !important; }
  .japanese-div .japanese-slider-img {
    width: 105px !important;
    height: 100px !important; }
  .cards-container .small-card .small-card-title h1 {
    font-size: 100px !important;
    line-height: 133px !important; }
  .cards-container .small-card .small-card-title span {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold; }
  .cards-container .small-card .small-card-text {
    font-size: 17px !important;
    line-height: 30px !important; }
  .cards-container .small-card .see-more-btn {
    background: #006466;
    color: #FFFCFB;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold; }
  .cards-container .big-card .big-card-title h1 {
    font-size: 100px !important;
    line-height: 133px !important; }
  .cards-container .big-card .big-card-body h2 {
    color: #006466 !important;
    font-family: Fraunces, sans-serif !important;
    font-size: 20px !important;
    line-height: 30px !important; }
  .cards-container .big-card .big-card-body p {
    color: #0D3236 !important;
    font-family: Montserrat, sans-serif !important;
    font-size: 17px !important;
    line-height: 30px !important; }
  .cards-container .big-card .big-card-body .big-card-buttons {
    position: relative;
    bottom: 50px; }
    .cards-container .big-card .big-card-body .big-card-buttons .book-now-btn {
      background-color: #FF8864;
      color: white; } }

@media (min-width: 992px) {
  body ul li {
    font-size: 20px !important; }
  .book-now-up {
    top: 40px; }
  .small-text {
    line-height: 20px !important; }
  .big-card .buttons-down {
    margin-top: 75px !important; }
  .big-card .big-card-buttons .all-packages-btn {
    background-color: #006466 !important;
    color: white;
    margin-right: 83px !important;
    margin-top: 7px !important; }
  .big-card .big-card-buttons .book-now-btn {
    background-color: #FF8864;
    color: white;
    width: 218px;
    height: 45px;
    margin-left: 0.5% !important;
    margin-bottom: 10px; }
  .small-card {
    min-height: 600px !important; }
  .slick-prev {
    left: 40% !important; }
  .slick-next {
    right: 40% !important; }
  button {
    font-size: 20px !important; }
  .second-list {
    margin-left: 2%; }
  .dark-dot,
  .light-dot {
    margin-top: 20px; }
  .first-slide--arrow {
    left: 44% !important;
    top: 7px; }
  .second-slide--arrow {
    right: 44% !important;
    top: 7px; }
  .dot-second-slide {
    margin-top: 95px; }
  .arrow-second-slide {
    top: 75px !important; }
  .about-us h1 {
    font-size: 40px;
    line-height: 60px; }
  .about-us p {
    font-size: 20px;
    line-height: 35px; }
  .about-us .second-slide--img {
    width: 100%; }
  .sea-standard-package {
    background: #FAEBDC;
    color: #006466; }
    .sea-standard-package h1 {
      font-size: 40px;
      line-height: 60px; }
    .sea-standard-package h3 {
      font-size: 30px;
      font-family: Montserrat, sans-serif !important;
      line-height: 37px; }
    .sea-standard-package p {
      font-size: 20px;
      line-height: 40px; }
  .sky-premium-package {
    background: #FFFCFB;
    color: #006466; }
    .sky-premium-package h1 {
      font-size: 40px;
      line-height: 60px; }
    .sky-premium-package h3 {
      font-size: 30px;
      font-family: Montserrat, sans-serif;
      line-height: 37px; }
    .sky-premium-package p {
      font-size: 20px;
      line-height: 40px; }
  .earth-eco-package h1 {
    font-size: 40px;
    line-height: 60px; }
  .earth-eco-package h3 {
    font-size: 30px;
    font-family: Montserrat, sans-serif;
    line-height: 37px; }
  .earth-eco-package p {
    font-size: 20px;
    line-height: 40px; }
  .earth-eco-package .package-card h1 {
    font-size: 100px;
    line-height: 133px; }
  .earth-eco-package .package-card p {
    font-size: 17px; }
  .earth-eco-package .package-card h2 {
    font-size: 20px; }
  .one-off-package {
    background: #fff; }
    .one-off-package h1 {
      font-family: Fraunces, sans-serif;
      font-size: 40px;
      line-height: 60px;
      font-weight: 700; }
    .one-off-package p {
      font-size: 20px;
      line-height: 40px; }
  .commercial-and-public-section h1 {
    font-size: 40px;
    line-height: 60px; }
  .commercial-and-public-section p {
    font-size: 20px;
    line-height: 40px; }
  .form__title {
    font-size: 50px;
    line-height: 35px; }
  .form__phone-number {
    font-size: 30px !important;
    line-height: 63px !important; }
  .form__mail {
    font-size: 30px !important;
    line-height: 63px !important; }
  .p-tellUs {
    font-size: 30px !important;
    line-height: 35px !important; }
  .form-label {
    font-size: 20px !important;
    line-height: 35px !important; }
  .personal-data-input {
    width: 50% !important; }
  .form__container p {
    font-size: 25px !important;
    line-height: 25px !important; }
  .form__container label {
    font-size: 20px !important;
    line-height: 35px !important; }
  .my-carousel-item .japanese-slider-img {
    width: 180px !important;
    height: 180px !important; }
  .sea-list tr td,
  .sky-list tr td {
    font-size: 20px; }
    .sea-list tr td:first-child,
    .sky-list tr td:first-child {
      padding-right: 4px; } }

body {
  line-height: 40px;
  color: #0D3236;
  overflow-x: hidden; }
  body input[type="date"]:invalid::-webkit-datetime-edit {
    color: #757575 !important; }
  body p,
  body ul,
  body li {
    font-family: Montserrat, sans-serif !important;
    font-size: 20px; }
  body h1,
  body h2,
  body h3,
  body h4,
  body h5 {
    font-family: Fraunces, sans-serif !important; }
  body h1 {
    font-weight: 700; }
  body ul {
    list-style-type: none !important;
    padding-left: 0; }
  body button {
    font-size: 17px !important;
    font-family: Montserrat, sans-serif !important; }
  body .date-p {
    font-size: 15px !important; }
  body .about-us h1 {
    color: #006466; }
  body.no-scroll {
    overflow: hidden; }

section {
  padding-left: 10%;
  padding-right: 10%; }

.green-button {
  background: #006466;
  font-size: 15px;
  line-height: 35px;
  color: white;
  font-family: Montserrat, sans-serif !important;
  width: 218px;
  height: 45px;
  border-radius: 23px;
  font-weight: 700;
  border: none; }

.orange-button {
  background: #FF8864 !important; }

.nh-btn-link {
  width: 378px !important;
  font-size: 20px !important;
  font-weight: bold; }

li {
  list-style-type: none !important; }

.magic-dots.slick-dots li button:before {
  color: #006466 !important; }

.slick-slide {
  text-align: center; }

.slick-slider {
  position: relative; }
  .slick-slider .slick-prev {
    top: 105%;
    left: 15%;
    width: 30px;
    height: 50px; }
  .slick-slider .slick-next {
    top: 105%;
    right: 15%;
    width: 30px;
    height: 50px; }

.fill-me {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.slider-text {
  margin-top: 10px;
  padding-left: 15%;
  padding-right: 15%;
  font-family: Montserrat, sans-serif !important;
  font-size: 15px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #0D3236 !important;
  text-align: left; }

.my-carousel-item {
  width: 85% !important;
  height: 330px !important;
  cursor: pointer; }
  .my-carousel-item img {
    margin-top: 30px; }

.japanese-div .japanese-title {
  line-height: 25px !important; }

.japanese-div p {
  line-height: 20px !important;
  margin-top: 5px; }

.japanese-div .japanese-slider-img {
  width: 150px !important;
  height: 150px !important; }

.eco-exact-span {
  position: relative;
  top: 570px; }

.arrow-toggle {
  transform: rotate(90deg);
  position: relative;
  top: 2px; }

.arrow-toggle.opened {
  transform: rotate(180deg) translate(-2px, 4px); }

.back-to-top-wrapper {
  background-color: white; }

.back-to-top {
  display: inline-block; }
